import React from "react"

import { Field, FieldArray, Form, Formik } from "formik"
import PropTypes from "prop-types"
import snakecase from "snakecase-keys"
import * as Yup from "yup"

import axios from "src/axios"

import AutocompleteSelect from "components/Forms/AutocompleteSelect"
import { ErrorMessage } from "components/Forms/Formik"

import MatchingQuestionsCount from "./MatchingQuestionsCount"
import styles from "./styles.module.scss"

const TailoredAssignmentForm = props => {
  const { id, apiNamespace, initialFormState } = props

  const handleSubmit = (values, actions) => {
    actions.setStatus({ msg: "Submitting..." })

    const clonedValues = { ...values }
    clonedValues.tailoredSectionsAttributes = values.sections
    delete clonedValues.sections
    const formData = {
      tailored_assignment: snakecase(clonedValues, {
        deep: true,
        exclude: ["_destroy"],
      }),
    }

    let action
    if (id) {
      action = axios.patch(
        `/api/${apiNamespace}/tailored_assignments/${id}`,
        formData
      )
    } else {
      action = axios.post(`/api/${apiNamespace}/tailored_assignments`, formData)
    }
    action.then(response => {
      const json = response.data
      if (json.success === true) {
        window.location = json.redirectTo
      } else {
        alert(
          `Unable to save assignment. Please ensure there are no validation errors and resubmit: ${json.errors.join(
            ", "
          )}`
        )
        actions.setSubmitting(false)
      }
    })
  }

  return (
    <Formik
      initialValues={initialFormState}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validationSchema={AssignmentSchema}
    >
      {({ values, status, isSubmitting, setFieldValue }) => (
        <Form>
          <div className="row">
            <div className="form-group col-6 col-1-2">
              <label className="form-label w-100">
                Name
                <Field
                  type="text"
                  className="form-control"
                  name="name"
                  autoFocus
                />
              </label>
              <ErrorMessage name="name" />
            </div>
            <div className="clear" />
          </div>

          <FieldArray name="sections">
            {arrayHelpers => (
              <React.Fragment>
                {values.sections.map(
                  (section, index) =>
                    !section._destroy && (
                      <fieldset key={index} className={styles.fieldset}>
                        <legend className={styles.legend}>
                          {section.name || "New Section"}
                        </legend>
                        <div className="row">
                          <div className="form-group col-6 col-2-4">
                            <label
                              className="form-label w-100"
                              htmlFor="section-title"
                            >
                              Title
                              <Field
                                type="text"
                                className="form-control"
                                placeholder="New Section"
                                name={`sections.${index}.name`}
                              />
                            </label>
                            <ErrorMessage name={`sections.${index}.name`} />
                          </div>

                          <div className="form-group col-6 col-1-4">
                            <label className="form-label w-100">
                              Order
                              <Field
                                type="number"
                                className="form-control"
                                name={`sections.${index}.order`}
                              />
                            </label>
                            <ErrorMessage name={`sections.${index}.order`} />
                          </div>

                          <div className="col-12">
                            <div className="w-100 grid grid-cols-3 gap-4">
                              <div className="form-group place-content-center">
                                <label className="form-label w-100">
                                  Number of Questions
                                  <Field
                                    type="number"
                                    className="form-control"
                                    name={`sections.${index}.maxQuestions`}
                                  />
                                </label>
                                <ErrorMessage
                                  name={`sections.${index}.maxQuestions`}
                                />
                              </div>

                              <div className="form-group place-content-center">
                                <label className="form-label w-100">
                                  Tags
                                  <AutocompleteSelect
                                    api={`/api/${apiNamespace}/tailored_questions/autocomplete_tags`}
                                    value={section.tags}
                                    selected={section.tagList.map(tag => ({
                                      label: tag,
                                      value: tag,
                                    }))}
                                    isMulti
                                    onChange={selected => {
                                      setFieldValue(
                                        `sections.${index}.tags`,
                                        selected
                                      )
                                      setFieldValue(
                                        `sections.${index}.tagList`,
                                        selected.map(tag => tag.label)
                                      )
                                    }}
                                  />
                                  <ErrorMessage
                                    name={`sections.${index}.tagList`}
                                  />
                                </label>
                              </div>

                              <div className="place-content-center">
                                <Field name={`sections.${index}.tagList`}>
                                  {({ field, form }) => (
                                    <MatchingQuestionsCount
                                      tags={field.value}
                                      apiNamespace={apiNamespace}
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>

                          <div className="col-12">
                            <div className="w-100 grid grid-cols-3 gap-4">
                              <div className="form-group place-content-center">
                                <label className="form-label w-100">
                                  Number of Question Groups
                                  <Field
                                    type="number"
                                    className="form-control"
                                    name={`sections.${index}.maxQuestionGroups`}
                                  />
                                </label>
                                <ErrorMessage
                                  name={`sections.${index}.maxQuestionGroups`}
                                />
                              </div>

                              <div className="form-group place-content-center">
                                <label className="form-label w-100">
                                  Tags
                                  <AutocompleteSelect
                                    api={`/api/${apiNamespace}/tailored_question_groups/autocomplete_tags`}
                                    value={section.groupTags}
                                    selected={section.groupTagList.map(tag => ({
                                      label: tag,
                                      value: tag,
                                    }))}
                                    isMulti
                                    onChange={selected => {
                                      setFieldValue(
                                        `sections.${index}.groupTags`,
                                        selected
                                      )
                                      setFieldValue(
                                        `sections.${index}.groupTagList`,
                                        selected.map(tag => tag.label)
                                      )
                                    }}
                                  />
                                </label>
                                <ErrorMessage
                                  name={`sections.${index}.groupTagList`}
                                />
                              </div>

                              <div className="form-group place-content-center">
                                <Field name={`sections.${index}.groupTagList`}>
                                  {({ field, form }) => (
                                    <MatchingQuestionsCount
                                      tags={field.value}
                                      apiNamespace={apiNamespace}
                                      grouped
                                    />
                                  )}
                                </Field>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12">
                            <button
                              className="btn btn-danger solid red"
                              type="button"
                              onClick={() =>
                                setFieldValue(
                                  `sections.${index}._destroy`,
                                  true
                                )
                              }
                            >
                              Remove Section
                            </button>
                          </div>
                        </div>
                      </fieldset>
                    )
                )}
                <div className="row">
                  <div className="col-12">
                    <button
                      className="btn btn-info solid blue"
                      type="button"
                      style={{ marginTop: 10 }}
                      onClick={() =>
                        arrayHelpers.push({
                          name: "",
                          tags: [],
                          groupTags: [],
                          tagList: [],
                          groupTagList: [],
                          maxQuestions: "",
                          maxQuestionGroups: "",
                          order: values.sections.length + 1,
                        })
                      }
                    >
                      Add Section
                    </button>
                  </div>
                </div>
                <ErrorMessage name="sections" />
              </React.Fragment>
            )}
          </FieldArray>

          <div className="row">
            <div className="col-12">
              <button
                type="submit"
                disabled={isSubmitting}
                className="btn btn-success solid green"
              >
                Save
              </button>
            </div>
            <div className="col-2 d-flex align-items-center">
              {status && status.msg && <div>{status.msg}</div>}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const AssignmentSchema = Yup.object().shape({
  name: Yup.string().required("Assignment name is required"),
  sections: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.mixed().when("_destroy", {
          is: undefined,
          then: Yup.string().required("Section title is required"),
        }),
        maxQuestions: Yup.number()
          .nullable(true)
          .min(
            1,
            "Leave this field blank if you don't want any non-grouped questions"
          ),
        maxQuestionGroups: Yup.number()
          .nullable(true)
          .min(
            1,
            "Leave this field blank if you don't want any grouped questions"
          ),
      })
    )
    .test(
      "min-sections",
      "At least 1 section is required",
      sections => sections.filter(a => !a._destroy).length > 0
    ),
})

TailoredAssignmentForm.propTypes = {
  id: PropTypes.number,
  apiNamespace: PropTypes.string.isRequired,
  initialFormState: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sections: PropTypes.array.isRequired,
  }).isRequired,
}

export default TailoredAssignmentForm
