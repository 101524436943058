import React, { useEffect, useState } from "react"

import PropTypes from "prop-types"

import axios from "src/axios"

import TailoredSectionForm from "components/TailoredSectionForm"

const AdminTailoredSectionFormContainer = props => {
  const { id } = props
  const [section, setSection] = useState(null)

  useEffect(() => {
    axios.get(`/api/admins/tailored_sections/${id}`).then(response => {
      const section = response.data
      setSection(section)
    })
  }, [id])

  if (!section) return null

  return (
    <TailoredSectionForm
      apiNamespace="admins"
      id={id}
      maxQuestions={section.maxQuestions}
      maxQuestionGroups={section.maxQuestionGroups}
      tags={section.tags}
      groupTags={section.groupTags}
      formState={{
        questionIds: section.tailoredQuestionIds,
        questionGroupIds: section.tailoredQuestionGroupIds,
      }}
    />
  )
}

AdminTailoredSectionFormContainer.propTypes = {
  id: PropTypes.number.isRequired,
}

export default AdminTailoredSectionFormContainer
